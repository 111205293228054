var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"chHh3M9kWrqxCOzPm7wHK"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_IGNORE } from 'utils/constants';

Sentry.init({
  dsn: 'https://bc12b897d5e44af6aa59e8826581f453@o227741.ingest.sentry.io/6021575',
  ignoreErrors: SENTRY_IGNORE,
  // Setting this to zero since we use Datadog for performance monitoring
  tracesSampleRate: 0.0,
  environment: process.env.NEXT_PUBLIC_ENV,
  integrations: [
    Sentry.feedbackIntegration({
      autoInject: false,
    })
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
